import {
    TextInput,
    useNotify,
    Edit,
    SimpleForm,
    SaveButton,
    Toolbar,
    useRedirect,
} from "react-admin";

export const UpdateEmail = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const onSuccess = ({ originId, email }) => {
        redirect(
            "show",
            "/admin/ceres_users",
            `/api/admin/ceres_users/${originId}`
        );
        notify(`Es wurde eine Verifizierungs-E-Mail an ${email} geschickt.`, {
            type: "success",
        });
    };

    return (
        <Edit
            title={"E-Mail Adresse bearbeiten"}
            mutationOptions={{ onSuccess }}
            mutationMode="pessimistic"
            {...props}
        >
            <SimpleForm toolbar={<UpdateEmailToolbar />}>
                <TextInput source="email" />
            </SimpleForm>
        </Edit>
    );
};

const UpdateEmailToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);
