import {
    HydraAdmin,
    ResourceGuesser,
    useIntrospection,
} from "@api-platform/admin";

import { defaultTheme, CustomRoutes } from "react-admin";

import { Route, Navigate } from "react-router-dom";

import { useState } from "react";

import UserIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import authProvider from "./utils/authProvider";
import dataProvider from "./utils/dataProvider";
import { ENTRYPOINT } from "./config/entrypoint";

import {
    InviteRequestList,
    InviteRequestCreate,
} from "./Components/InviteRequest/InviteRequest";
import {
    CeresUsersList,
    CeresUsersShow,
} from "./Components/CeresUser/CeresUser";
import { UpdateNote } from "./Components/CeresUser/UpdateNote";
import { UpdateEmail } from "./Components/CeresUser/UpdateEmail";

import { MyLayout } from "./Components/Layout/MyLayout";

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Navigate to="/login" />;
};

const CatchAll = () => {
    return <Navigate to="/" />;
};

const App = () => {
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    return (
        <HydraAdmin
            disableTelemetry
            theme={defaultTheme}
            layout={MyLayout}
            entrypoint={ENTRYPOINT}
            authProvider={authProvider}
            dataProvider={dataProvider(setRedirectToLogin)}
            catchAll={CatchAll}
        >
            {redirectToLogin ? (
                <CustomRoutes>
                    <Route path="/" element={<RedirectToLogin />} />
                    <Route path="/:any" element={<RedirectToLogin />} />
                </CustomRoutes>
            ) : (
                <>
                    <ResourceGuesser
                        icon={UserIcon}
                        options={{ label: "Ceres User" }}
                        name="admin/ceres_users"
                        list={CeresUsersList}
                        show={CeresUsersShow}
                        edit={false}
                        create={false}
                    />
                    <ResourceGuesser
                        name="admin/update_emails"
                        list={false}
                        show={false}
                        edit={UpdateEmail}
                        create={false}
                    />

                    <ResourceGuesser
                        name="admin/update_notes"
                        list={false}
                        show={false}
                        edit={UpdateNote}
                        create={false}
                    />
                    <ResourceGuesser
                        icon={EmailIcon}
                        options={{ label: "Einladung" }}
                        name="admin/invite_requests"
                        list={InviteRequestList}
                        create={InviteRequestCreate}
                        edit={false}
                        show={false}
                    />
                </>
            )}
        </HydraAdmin>
    );
};

export default App;
