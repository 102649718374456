import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    List,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
} from "react-admin";

export const InviteRequestList = (props) => (
    <List exporter={false} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField label="E-Mail" source="email" sortable={false} />
            <TextField label="Name" source="fullName" sortable={false} />
            <TextField
                label="Profession"
                source="profession"
                sortable={false}
            />
            <TextField label="Land" source="country" sortable={false} />
            <TextField
                label="Institution / Praxis"
                source="basicContact.companyName"
                sortable={false}
            />
            <BooleanField
                label="Beruf verifiziert"
                source="professionVerified"
            />
        </Datagrid>
    </List>
);

export const InviteRequestCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput type="email" label="E-Mail" source="email" />
                <SalutationInput />
                <TextInput type="text" label="Vorname" source="firstName" />
                <TextInput type="text" label="Nachname" source="lastName" />
                <CountryInput />
                <ProfessionInput />
                <BooleanInput
                    label="Beruf verifiziert"
                    source="professionVerified"
                    defaultValue={true}
                />
                <TextInput
                    type="text"
                    label="Institution / Praxis"
                    source="companyName"
                />
                <TextInput type="text" label="Strasse" source="streetAddress" />
                <TextInput
                    type="text"
                    label="Postleitzahl"
                    source="postalCode"
                />
                <TextInput type="text" label="Ort" source="locality" />
                <TextInput type="tel" label="Telefon" source="phoneNumber" />
                <TextInput type="url" label="Website" source="website" />
                <CommunicationLanguageInput />
            </SimpleForm>
        </Create>
    );
};

const SalutationInput = (props) => {
    return (
        <SelectInput
            label="Anrede"
            source="salutation"
            choices={[
                { id: "male", name: "Herr" },
                { id: "female", name: "Frau" },
                { id: "undefined", name: "keine Anrede" },
            ]}
        />
    );
};

const CountryInput = (props) => {
    return (
        <SelectInput
            label="Land"
            source="country"
            choices={[
                { id: "germany", name: "Deutschland" },
                { id: "switzerland", name: "Schweiz" },
                { id: "liechtenstein", name: "Liechtenstein" },
                { id: "austria", name: "Österreich" },
                { id: "france", name: "Frankreich" },
                { id: "italy", name: "Italien" },
                { id: "belgium", name: "Belgien" },
                { id: "czechia", name: "Tschechien" },
                { id: "denmark", name: "Dänemark" },
                { id: "greece", name: "Griechenland" },
                { id: "netherlands", name: "Niederlande" },
                { id: "portugal", name: "Portugal" },
                { id: "spain", name: "Spanien" },
                { id: "sweden", name: "Schweden" },
            ]}
        />
    );
};

const ProfessionInput = (props) => {
    return (
        <SelectInput
            label="Beruf"
            source="profession"
            choices={[
                { id: "apotheker", name: "Apotheker*in" },
                { id: "arzt", name: "Arzt/Ärztin" },
                { id: "drogistEFZ", name: "Drogist*in EFZ" },
                { id: "drogistHF", name: "Drogist*in HF" },
                {
                    id: "fachpersonInAusbildung",
                    name: "Fachperson in Ausbildung",
                },
                { id: "hebamme", name: "Hebamme" },
                { id: "heilpraktiker", name: "Heilpraktiker*in" },
                {
                    id: "medizinischerPraxisassistent",
                    name: "Medizinische Praxisassistent*in",
                },
                { id: "naturheilpraktiker", name: "Naturheilpraktiker*in" },
                { id: "otherMedicalProfession", name: "Sonstiger medizinischer Beruf" },
                { id: "otherNonmedicalProfession", name: "Sonstiger nicht-medizinischer Beruf" },
                { id: "pharmaassistent", name: "Pharmaassistent*in" },
                { id: "psychotherapeut", name: "Psychotherapeut*in" },
                { id: "pta", name: "PTA" },
                { id: "tierarzt", name: "Tierarzt/Tierärztin" },
                { id: "tierheilpraktiker", name: "Tierheilpraktiker*in" },
            ]}
        />
    );
};

const CommunicationLanguageInput = (props) => {
    return (
        <SelectInput
            label="Sprache"
            source="communicationLanguage"
            choices={[
                { id: "german", name: "Deutsch" },
                { id: "french", name: "Französisch" },
            ]}
        />
    );
};
