import {
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
} from "@api-platform/admin";

import { ENTRYPOINT } from "../config/entrypoint";

import { parseHydraDocumentation } from "@api-platform/api-doc-parser";

import { HttpError } from "react-admin";

const getHeaders = () =>
    localStorage.getItem("token")
        ? {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        : {};

const fetchHydra = (url, options = {}) => {
    return baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    }).catch((error) => {
        if (error.status === 422) {
            if (!error.body || !error.body[0]) {
                return null;
            }

            const content = error.body[0];
            const violationKey = Object.keys(content).find((key) =>
                key.includes("violations")
            );

            if (!violationKey) {
                return null;
            }

            const base = violationKey.substring(0, violationKey.indexOf("#"));
            const violations = content[violationKey].reduce(
                (violations, violation) =>
                    !violation[`${base}#propertyPath`] ||
                    !violation[`${base}#message`]
                        ? violations
                        : {
                              ...violations,
                              [violation[`${base}#propertyPath`][0]["@value"]]:
                                  violation[`${base}#message`][0]["@value"],
                          },
                {}
            );

            throw new HttpError(error.message, 422, {
                errors: violations,
            });
        }
        throw error;
    });
};

const apiDocumentationParser = (setRedirectToLogin) => async () => {
    try {
        setRedirectToLogin(false);

        return await parseHydraDocumentation(ENTRYPOINT, {
            headers: getHeaders,
        });
    } catch (result) {
        const { api, response, status } = result;
        if (status !== 401 || !response) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");

        setRedirectToLogin(true);

        return {
            api,
            response,
            status,
        };
    }
};

const hydraDataProvider = (setRedirectToLogin) =>
    baseHydraDataProvider({
        entrypoint: ENTRYPOINT,
        httpClient: fetchHydra,
        apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
    });

const extendedDataProvider = (setRedirectToLogin) => {
    return {
        ...hydraDataProvider(setRedirectToLogin),
        verify: ({ record }) => {
            return Promise.resolve(
                fetchHydra(
                    new URL(record["@id"] + "/verify_profession", ENTRYPOINT),
                    {
                        method: "PUT",
                        body: JSON.stringify([]),
                    }
                )
            ).then(({ json }) => ({
                data: json,
            }));
        },
        download: ({ path }) => {
            return Promise.resolve(
                fetch(ENTRYPOINT + path, {
                    method: "GET",
                    headers: getHeaders(),
                })
            ).then(async function (result) {
                const blob = await result.blob();
                const href = window.URL.createObjectURL(blob);

                return {
                    data: href,
                };
            });
        },
    };
};

export default extendedDataProvider;
