import jwtDecode from "jwt-decode";
import { ENTRYPOINT } from "./../config/entrypoint";

const auth = {
    login: async ({ username, password }) => {
        const request = new Request(`${ENTRYPOINT}/authentication_token`, {
            method: "POST",
            body: JSON.stringify({ username: username, password }),
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        const response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        const { token } = await response.json();
        localStorage.setItem("token", token);
    },
    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            // @ts-ignore
            if (
                !localStorage.getItem("token") ||
                new Date().getTime() / 1000 >
                    jwtDecode(localStorage.getItem("token"))?.exp
            ) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: (err) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(),
};

export default auth;
