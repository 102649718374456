import {
    Datagrid,
    List,
    Labeled,
    SelectInput,
    TextField,
    BooleanField,
    ShowButton,
    TextInput,
    Button,
    Show,
    TabbedShowLayout,
    Tab,
    ReferenceField,
    TopToolbar,
    DeleteButton,
    useRecordContext,
    useNotify,
    useRefresh,
    useDataProvider,
} from "react-admin";

import { useMutation } from "react-query";
import { useRef } from "react";

import ContentCreate from "@mui/icons-material/Create";
import OpenInNew from "@mui/icons-material/OpenInNew";

export const CeresUsersList = (props) => (
    <List
        filters={ceresUserFilters}
        exporter={false}
        bulkActionButtons={false}
        sort={{ field: "last_name", order: "ASC" }}
        {...props}
    >
        <Datagrid rowClick="show">
            <TextField source="email" addLabel={true} sortable={false} />
            <TextField
                label="Name"
                source="fullName"
                sortable={true}
                sortBy="last_name"
            />
            <TextField
                label="Verifikationsstatus"
                source="professionVerificationState"
                sortable={false}
            />
            <TextField label="Land" source="countryIsoCode" sortable={false} />
            <TextField
                label="Registrierungsdatum"
                source="creationDate"
                sortable={true}
                sortByOrder="DESC"
                sortBy="creation"
            />
            <ShowButton />
        </Datagrid>
    </List>
);

export const CeresUsersShow = (props) => {
    return (
        <Show actions={<CeresUserActions />} title={"Ceres User"} {...props}>
            <TabbedShowLayout>
                <Tab label="Allgemein">
                    <TextField label="Name" source="fullName" />

                    <ReferenceField
                        label="E-Mail"
                        source="updateEmail"
                        reference="admin/update_emails"
                    >
                        <>
                            <ContentCreate />
                            <TextField source="email" />
                        </>
                    </ReferenceField>

                    <TextField
                        label="Verifikationsstatus"
                        source="professionVerificationState"
                    />

                    <DownloadLink basePath={props.basePath} record={props.data}>
                        <>
                            <OpenInNew />
                            <Button label="Berufsnachweis anschauen" />
                        </>
                    </DownloadLink>

                    <VerifyButton
                        basePath={props.basePath}
                        record={props.data}
                    />

                    <TextField label="Beruf" source="profession" />

                    <TextField
                        label="Institution / Praxis"
                        source="contactCompanyName"
                    />

                    <TextField label="Land" source="country" />

                    <ReferenceField
                        label="Notiz"
                        source="updateNote"
                        reference="admin/update_notes"
                    >
                        <>
                            <ContentCreate />
                            <TextField source="note" />
                        </>
                    </ReferenceField>
                </Tab>
                <Tab label="Adresse / Kontakt">
                    <TextField label="Strasse" source="contactStreetAddress" />

                    <TextField
                        label="Postleitzahl"
                        source="contactPostalCode"
                    />
                    <TextField label="Ort" source="contactLocality" />

                    <TextField label="Land" source="country" />

                    <TextField label="Telefon" source="contactPhoneNumber" />

                    <TextField
                        label="Firma (Rechnungsanschrift)"
                        source="billingCompanyName"
                        addLabel={true}
                    />

                    <TextField
                        label="Name (Rechnungsanschrift)"
                        source="billingFullName"
                    />
                    <TextField
                        label="Strasse (Rechnungsanschrift)"
                        source="billingStreetAddress"
                    />
                    <TextField
                        label="Postleitzahl (Rechnungsanschrift)"
                        source="billingPostalCode"
                    />
                    <TextField
                        label="Ort (Rechnungsanschrift)"
                        source="billingLocality"
                    />
                    <TextField
                        label="Land (Rechnungsanschrift)"
                        source="billingCountry"
                    />

                    <TextField
                        label="Firma (Lieferanschrift)"
                        source="deliveryCompanyName"
                    />
                    <TextField
                        label="Name (Lieferanschrift)"
                        source="deliveryFullName"
                    />
                    <TextField
                        label="Strasse (Lieferanschrift)"
                        source="deliveryStreetAddress"
                    />
                    <TextField
                        label="Postleitzahl (Lieferanschrift)"
                        source="deliveryPostalCode"
                    />
                    <TextField
                        label="Ort (Lieferanschrift)"
                        source="deliveryLocality"
                    />
                    <TextField
                        label="Land (Lieferanschrift)"
                        source="deliveryCountry"
                    />
                </Tab>
                <Tab label="Sonstiges">
                    <BooleanField
                        label="Email-Adresse verifiziert"
                        source="emailVerified"
                    />
                    <TextField label="Letzter Login" source="lastLoginAt" />
                    <TextField label="Account erstellt" source="creationDate" />
                    <PendingEmail />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const ceresUserFilters = [
    <TextInput source="q" label="Suche" alwaysOn allowEmpty />,
    <SelectInput
        alwaysOn
        source="professionVerificationState"
        label="Berufsstatus"
        choices={[
            { id: "missing", name: "Missing" },
            { id: "pending", name: "Pending" },
            { id: "verified", name: "Verified" },
        ]}
    />,
    <SelectInput
        alwaysOn
        source="country"
        label="Land"
        choices={[
            { id: "germany", name: "Deutschland" },
            { id: "switzerland", name: "Schweiz" },
            { id: "liechtenstein", name: "Liechtenstein" },
            { id: "others", name: "Andere" },
        ]}
    />,
    <SelectInput
        alwaysOn
        source="profession"
        label="Beruf"
        choices={[
            { id: "apotheker", name: "Apotheker*in" },
            { id: "arzt", name: "Arzt/Ärztin" },
            { id: "drogistEFZ", name: "Drogist*in EFZ" },
            { id: "drogistHF", name: "Drogist*in HF" },
            { id: "fachpersonInAusbildung", name: "Fachperson in Ausbildung" },
            { id: "hebamme", name: "Hebamme" },
            { id: "heilpraktiker", name: "Heilpraktiker*in" },
            {
                id: "medizinischerPraxisassistent",
                name: "Medizinische Praxisassistent*in",
            },
            { id: "naturheilpraktiker", name: "Naturheilpraktiker*in" },
            { id: "otherMedicalProfession", name: "Sonstiger medizinischer Beruf" },
            { id: "otherNonmedicalProfession", name: "Sonstiger nicht-medizinischer Beruf" },
            { id: "pharmaassistent", name: "Pharmaassistent*in" },
            { id: "psychotherapeut", name: "Psychotherapeut*in" },
            { id: "pta", name: "PTA" },
            { id: "tierarzt", name: "Tierarzt/Tierärztin" },
            { id: "tierheilpraktiker", name: "Tierheilpraktiker*in" },
        ]}
    />,
];

const CeresUserActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <DeleteButton
            basePath={basePath}
            record={data}
            resource={resource}
            undoable={false}
            confirmTitle="CERES User löschen?"
            confirmContent="Wollen Sie den CERES User wirklich unwiderruflich löschen?"
            label="Löschen"
        />
    </TopToolbar>
);

const PendingEmail = () => {
    const record = useRecordContext();
    if (record.pendingEmail !== null) {
        return (
            <Labeled label="nicht verifizierte Email">
                <TextField source="pendingEmail" />
            </Labeled>
        );
    }

    return null;
};

const VerifyButton = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const { mutate: verifyProfession, isLoading } = useMutation(
        () =>
            dataProvider.verify({
                record: record,
            }),
        {
            onSuccess: ({ data }) => {
                refresh();
                notify("Beruf verifiziert", { type: "success" });
            },
            onFailure: ({ error }) => {
                notify(`Es ist etwas schief gegangen`, { type: "warning" });
            },
        }
    );

    if (record.professionVerificationState !== "verified") {
        return (
            <Button
                label="Beruf Verifizieren"
                onClick={verifyProfession}
                disabled={isLoading}
            />
        );
    }

    return null;
};

const DownloadLink = ({ children }) => {
    const record = useRecordContext();
    const link = useRef();
    const dataProvider = useDataProvider();

    const notify = useNotify();

    const { mutate } = useMutation(
        () =>
            dataProvider.download({
                path: record.professionVerificationDocument?.path,
            }),
        {
            onSuccess: ({ data }) => {
                if (link.current.href) {
                    return;
                }

                link.current.onClick = null;
                link.current.href = data;

                link.current.click();
            },
            onFailure: ({ error }) => {
                notify(`Es ist etwas schief gegangen`, { type: "warning" });
            },
        }
    );

    if (record.professionVerificationDocument !== null) {
        return (
            <a
                role="button"
                ref={link}
                onClick={mutate}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        );
    }

    return null;
};
