import {
    TextInput,
    useNotify,
    Edit,
    SimpleForm,
    SaveButton,
    Toolbar,
    useRedirect,
} from "react-admin";

export const UpdateNote = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const onSuccess = ({ originId }) => {
        redirect(
            "show",
            "/admin/ceres_users",
            `/api/admin/ceres_users/${originId}`
        );
        notify(`Der Kommentar wurde gespeichert`, { type: "success" });
    };

    return (
        <Edit
            title={"Notiz bearbeiten"}
            mutationOptions={{ onSuccess }}
            mutationMode="pessimistic"
            {...props}
        >
            <SimpleForm toolbar={<UpdateNoteToolbar />}>
                <TextInput source="note" multiline fullWidth />
            </SimpleForm>
        </Edit>
    );
};

const UpdateNoteToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);
